import React, {useState, useEffect, useRef} from 'react';
import FormInput from 'components/inputs/form-input/form-input';
import RoundButton from 'components/buttons/round-button/round-button';
import {useTranslation} from 'react-i18next';
import {vars} from 'utils/variables';
import {sha512} from 'utils/functions';
import validationEngine from 'devextreme/ui/validation_engine';
import {getApplicationSettings, logInOtpRequest, logInRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {Link} from 'react-router-dom';
import PhoneInput from 'components/inputs/phone-input/phone-input';
import LanguageSwitcher from 'components/language-switcher/language-switcher';
import Recovery from 'components/recovery/recovery';

const {
  POPUP_LOGO_FILE_NAME,
  REG_EXP,
  AUTH_TYPE,
} = vars;

const NewLogin = (props) => {
  const {onLoginClick} = props;
  const {t} = useTranslation();
  const recaptchaRef = useRef(null);

  const [otp, setOtp] = useState('');
  const [isRecoveryVisible, setIsRecoveryVisible] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [info, setInfo] = useState({
    InfoText: '',
    SendChannelID: 0,
  });

  const [otpInfo, setOtpInfo] = useState(null);
  const [username, setUsername] = useState('');
  const [isPhoneLogin, setIsPhoneLogin] = useState(false);

  const logIn = (username, response) => {
    if (response && response.InfoText) {
      setInfo(response);
      setUsername(username);
    } else {
      onLoginClick(username);
    }
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6Leo1KYnAAAAAC1q-OumYOFrUz-SaBfrxQS5io9v';
    document.body.appendChild(script);

    script.onload = () => {
      recaptchaRef.current = window.grecaptcha;
    };

    return () => {
      document.body.removeChild(script);
      recaptchaRef.current = null;
      document.querySelector('div.grecaptcha-badge').remove();
    };
  }, []);

  const sendLoginRequest = async () => {
    const result = validationEngine.validateGroup('loginGroup');

    if (!result.isValid) {
      notifyApp('Please fix the validation errors');
      return;
    }

    const hashedPassword = await sha512(password);

    if (hashedPassword) {
      const token = await window.grecaptcha.execute('6Leo1KYnAAAAAC1q-OumYOFrUz-SaBfrxQS5io9v', {action: 'Login'});
      const request = await logInRequest(login, hashedPassword, token);

      if (request.isSuccess) {
        if (parseInt(request.authType) === AUTH_TYPE.SIMPLE) {
          // get settings
          const settingsResponse = await getApplicationSettings();

          if (settingsResponse.isSuccess) {
            window.localStorage.setItem('userTypeID', settingsResponse.userTypeID);
            logIn(settingsResponse.userName, request.response);
          } else {
            notifyApp(settingsResponse.error);
          }
        } else {
          setOtpInfo(request.response);
        }
      } else {
        notifyApp(request.error);
      }
    } else {
      notifyApp('Client WebAPI error');
    }
  }

  const openRecovery = (e) => {
    e.preventDefault();
    setIsRecoveryVisible(true);
  }

  const closeRecovery = () => {
    setIsRecoveryVisible(false);
  }

  const sendLoginOtp = async () => {
    const optRequest = await logInOtpRequest(otp);

    if (optRequest.isSuccess) {
      const settingsResponse = await getApplicationSettings();

      if (settingsResponse.isSuccess) {
        window.localStorage.setItem('userTypeID', settingsResponse.userTypeID);
      } else {
        notifyApp(settingsResponse.error);
      }

      onLoginClick(settingsResponse.userName);
    } else {
      notifyApp(optRequest.error);
    }
  }

  return (
    <div className={'adaptive-block-right'}>
      <div className={'login-form'}>
        <div className={'middle-block'}>
          <p className={'form-title'}>
            {process.env.REACT_APP_BRAND === 's3' ? t('COMMON.LOG_TO_BRAND') : t('COMMON.LOG_TO')}
          </p>
          {isPhoneLogin ? (
            <PhoneInput
              readOnly={!!otpInfo}
              value={login}
              type={'phone'}
              label={t('COMMON.LOGIN_PHONE_ACCOUNT_PLACEHOLDER')}
              validation={{
                group: 'loginGroup',
                rules: [
                  {type: 'required', message: t('ERROR_MSG.REQUIRED')},
                  {type: 'pattern', message: t('ERROR_MSG.WRONG_PHONE'), pattern: REG_EXP.PHONE_NUMBER},
                ],
              }}
              onInput={({event}) => {
                const value = event.target.value.replace('+', '');
                setLogin(value);

                if (!(/^\d{3}/.test(value))) {
                  setIsPhoneLogin(false);
                }
              }}
            />
          ) : (
            <FormInput
              readOnly={!!otpInfo}
              value={login}
              label={t('COMMON.LOGIN_ACCOUNT_PLACEHOLDER')}
              type={'text'}
              validation={{
                group: 'loginGroup',
                rules: [
                  {type: 'required', message: t('ERROR_MSG.REQUIRED')},
                ],
              }}
              onValueChanged={({value}) => {
                setLogin(value);
              }}
              /*onInput={({event}) => {
                setLogin(event.target.value);

                if (/^\d{3}/.test(event.target.value)) {
                  setIsPhoneLogin(true);
                }
              }}*/
            />
          )}
          {otpInfo ? (
            <>
              <div className={'anti-autocomplete'}>
                <input type='text' name='username' autoComplete='username'/>
                <input type={'password'}/>
              </div>
              <FormInput
                value={otp}
                onEnterKey={sendLoginOtp}
                label={t('COMMON.AUTH_CODE')}
                type={'text'}
                validation={{
                  group: 'otpGroup',
                  rules: [
                    {type: 'required', message: t('ERROR_MSG.REQUIRED')},
                  ]
                }}
                onValueChanged={({value}) => {
                  setOtp(value);
                }}
              />
              <div className={'info-panel'}>
                <div className={'info-panel-icon'}>
                  <img width={'60px'} src={`./images/sharpay/${POPUP_LOGO_FILE_NAME[otpInfo.SendChannelID]}`}
                       alt={'popup-logo'}/>
                </div>
                <div className={'info-panel-text'}>
                  {otpInfo.InfoText}
                </div>
              </div>
              <RoundButton
                text={t('COMMON.LOGIN')}
                icon={''}
                onClick={sendLoginOtp}
                customClass={'size14 new-button'}
              />
            </>
          ) : (
            <>
              <FormInput
                label={t('COMMON.LOGIN_PASS_PLACEHOLDER')}
                type={'password'}
                validation={{
                  group: 'loginGroup',
                  rules: [
                    {type: 'required', message: t('ERROR_MSG.REQUIRED')},
                  ]
                }}
                onValueChanged={({value}) => {
                  setPassword(value);
                }}
              />
              <div className={'forgot-password'}>
                <a href='/recovery' onClick={openRecovery}>{t('COMMON.FORGOT_PASS')}</a>
              </div>
              <RoundButton
                text={t('COMMON.LOGIN')}
                icon={''}
                onClick={sendLoginRequest}
                customClass={'size14 new-button'}
              />
            </>
          )}
          <Recovery
            visible={isRecoveryVisible}
            container={'#login-page'}
            title={t('PASSWORD_RECOVERY_TITLE')}
            maxWidth={650}
            handleClose={closeRecovery}
          />
        </div>

        <div className={'bottom-block'}>
          <p className={'secondary-text'}>{t('COMMON.CHOOSE_LANG')}</p>
          <LanguageSwitcher/>
          <p className={'secondary-text'}>
            {t('COMMON.DONT_HAVE_ACC')} <Link className="link" to="/registration">{t('COMMON.REGISTRATION')}</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewLogin;
