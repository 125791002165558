import React from 'react';
import DataGrid, {
  Button as GridButton,
  Column,
  ColumnChooser,
  FilterRow,
  Paging,
  RemoteOperations,
  Scrolling,
  Sorting,
} from 'devextreme-react/data-grid';
import {createFilter, makeCalculateFilterExpression} from 'utils/customFilters';
import {
	amountWithCurrencyCode,
	customizeDateCell,
	customizePaymentTypeColor,
	customizeStatus,
} from 'utils/customize-table-cell';
import {withTranslation} from 'react-i18next';
import {valueToArray} from 'utils/functions';
import {Template} from 'devextreme-react/core/template';
import ExportDataGrid from 'components/export-data-grid/export-data-grid';
import {getWallettoPayments, exportWallettoCommonPaymentOrder} from 'services/requestConsts';
import PaymentDetail from 'components/popup/paymentDetail/payment-detail';
import {RoundButton} from 'components/buttons/round-button';
import classNames from 'classnames';

class WallettoTable extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showFilter: false,
			isShowExportDatePopup: false,
		};

		this.gridRef = React.createRef();

		this.filterOptions = {
			PaymentTypeName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('PaymentTypeID'),
				options: {
					object: 'PaymentType',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			},
			PaymentStatusName: {
				type: 'dictionary',
				filterOperations: [],
				calculateFilterExpression: makeCalculateFilterExpression('PaymentStatusID'),
				options: {
					object: 'PaymentStatus',
					displayName: 'Name',
					keyName: 'ID',
					value: undefined,
					onValueChanged: () => {
					},
				}
			}
		}

	}

	onEditorPreparing = (e) => {
		const {t} = this.props;
		const {dataField, editorElement, parentType} = e;

		if (this.filterOptions.hasOwnProperty(dataField) && parentType === 'filterRow') {
			const settings = this.filterOptions[dataField];
			e.cancel = true;

			editorElement.appendChild(createFilter({
				...settings,
				options: {
					...settings.options,
					placeholder: t('MAIN.PAYMENT_HISTORY_TABLE.TABLE_TOPBAR.COLUMN_CHOOSER_SELECT'),
					parentWidth: editorElement.clientWidth,
					value: valueToArray(e.value),
					onValueChanged: e.editorOptions.onValueChanged
				}
			}));
		}
	};

	toggleColumnChooser() {
		const {showColumnChooser} = this.state;
		if (showColumnChooser) {
			this.gridRef.current.instance.showColumnChooser();
		} else {
			this.gridRef.current.instance.hideColumnChooser();
		}
	}

	onToolbarPreparing = (e) => {
		const {t} = this.props;

		e.toolbarOptions.items.unshift({
			location: 'after',
			widget: 'dxButton',
			options: {
				hint: t('COMMON.EXPORT_TO_EXCEL'),
				icon: 'xlsxfile',
				onClick: () => {
					const {isShowExportDatePopup} = this.state;
					this.setState({isShowExportDatePopup: !isShowExportDatePopup});
				}
			}
		}, {
			location: 'before',
			template: 'customToolbar'
		}, {
			location: 'after',
			widget: 'dxButton',
			options: {
				icon: 'mdi mdi-view-column',
				onClick: () => {
					const {showColumnChooser} = this.state;
					this.setState({
						showColumnChooser: !showColumnChooser
					}, this.toggleColumnChooser);
				}
			}
		}, {
			location: 'after',
			widget: 'dxButton',
			options: {
				icon: 'mdi mdi-filter-variant',
				onClick: () => {
					const {showFilter} = this.state;
					this.setState({
						showFilter: !showFilter
					});
				}
			}
		});
	};

	toolbarItemCustomize = () => {
		const {t} = this.props;
		return (
			<div className={'recent-operation'}>
				<span className={'recent-operation-text'}>
					{t('MAIN.PAYMENT_HISTORY_TABLE.TABLE_TOPBAR.RECENT_OPERATIONS')}
				</span>
			</div>
		);
	};

	getExportWallettoParams = () => {
		const {selectedDetailID} = this.props;
		return getWallettoPayments(selectedDetailID);
	}

	closeExportDatePopup = () => {
		return this.setState({isShowExportDatePopup: false});
	}

	showPaymentDetails = (row) => {
		const {detailID} = this.state;

		if (row.key !== detailID) {
			this.setState({
				detailID: row.key,
			});
		} else {
			this.closeDetailPopup();
		}
	}

	closeDetailPopup = () => {
		this.setState({
			detailID: null,
		});
	}

	render() {
		const {showFilter, isShowExportDatePopup, detailID} = this.state;
		const {t, payments, viewPrecision} = this.props;

		return (
			<div className={'full-height'}>
				<PaymentDetail
					t={t}
					accountType={1}
					visible={!!detailID}
					onHiding={this.closeDetailPopup}
					detailID={detailID}
				/>
				<DataGrid
					id={'accounts-walletto'}
					ref={this.gridRef}
					alignment={'center'}
					className={'dx-card wide-card'}
					dataSource={payments}
					hoverStateEnabled={false}
					showBorders={false}
					focusedRowEnabled={false}
					columnAutoWidth={true}
					columnHidingEnabled={true}
					onEditorPreparing={this.onEditorPreparing}
					onToolbarPreparing={this.onToolbarPreparing}
					keyExpr={'ID'}
					height={'100%'}
				>
					<ColumnChooser
						title={t('MAIN.PAYMENT_HISTORY_TABLE.TABLE_TOPBAR.COLUMN_CHOOSER_TITLE')}
						emptyPanelText={t('MAIN.PAYMENT_HISTORY_TABLE.TABLE_TOPBAR.COLUMN_CHOOSER_DESCRIPTION')}
					/>
					<RemoteOperations
						paging
						filtering
					/>
					<Paging enabled defaultPageSize={50}/>
					<FilterRow visible={showFilter}/>
          <Sorting mode={'none'}/>
					<Scrolling
						mode={'infinite'}
						showScrollbar='onHover'
					/>
					<Column
						width={100}
						alignment={'right'}
						dataField={'ID'}
						caption={t(exportWallettoCommonPaymentOrder['ID'])}
						hidingPriority={2}
					/>
					<Column
						width={130}
						alignment={'left'}
						dataField={'InsDate'}
						hidingPriority={5}
						dataType={'date'}
						caption={t(exportWallettoCommonPaymentOrder['InsDate'])}
						format={'dd.MM.yy, HH:mm'}
						cellRender={customizeDateCell}
						selectedFilterOperation={'between'}
					/>
					<Column
						width={170}
						alignment={'left'}
						dataField={'PaymentTypeName'}
						caption={t(exportWallettoCommonPaymentOrder['PaymentTypeName'])}
						filterOperations={this.filterOptions['PaymentTypeName'].filterOperations}
						calculateFilterExpression={this.filterOptions['PaymentTypeName'].calculateFilterExpression}
						cellRender={customizePaymentTypeColor}
						hidingPriority={3}
					/>
					<Column
						alignment={'left'}
						dataField={'ViewDescription'}
						caption={t(exportWallettoCommonPaymentOrder['ViewDescription'])}
						hidingPriority={4}
						width={800}
					/>
					<Column
						alignment={'right'}
						dataField={'Amount'}
						caption={t(exportWallettoCommonPaymentOrder['Amount'])}
						hidingPriority={1}
						cellRender={(e) => {
							return amountWithCurrencyCode(e, 'AccountCurrencyCode', viewPrecision);
						}}
					/>
					<Column
						alignment={'left'}
						dataField={'PaymentStatusName'}
						caption={t(exportWallettoCommonPaymentOrder['PaymentStatusName'])}
						filterOperations={this.filterOptions['PaymentStatusName'].filterOperations}
						calculateFilterExpression={this.filterOptions['PaymentStatusName'].calculateFilterExpression}
						hidingPriority={0}
						cellRender={customizeStatus}
					/>
					<Column
						type={'buttons'}
						width={120}
					>
						<GridButton
							render={(props) => {
								return (
									<RoundButton
										id={`grid-btn-${props.key}`}
										height={20}
										text={props.key === detailID ? t('COMMON.CLOSE') : t('COMMON.OPEN')}
										customClass={classNames('blue grid-button size10', {
											'state-active': props.key === detailID,
										})}
										onClick={() => {
											this.showPaymentDetails(props);
										}}
									/>
								);
							}}
						/>
					</Column>
					<Template name={'customToolbar'} render={this.toolbarItemCustomize}/>
				</DataGrid>
				<ExportDataGrid
					ref={this.gridRef}
					getGridParams={this.getExportWallettoParams}
					isShowExportDatePopup={isShowExportDatePopup}
					closeExportDatePopup={this.closeExportDatePopup}
					exportCaptions={exportWallettoCommonPaymentOrder}
				/>
			</div>
		);
	}
}

export default withTranslation()(WallettoTable);
