import {vars, dateFormats} from './variables';
import dayjs from 'dayjs';

const {REG_EXP: {SPACES}, DEFAULT_PRECISION} = vars;
const {viewDateFormat} = dateFormats;

export const dateToFormat = (value, formatter = viewDateFormat) => {
  if (value == null)
    return value;

  return dayjs(new Date(value)).format(formatter);
};

export const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const hasNull = (obj) => {
  for (let key in obj) {
    if (obj[key] == null)
      return true;
  }
  return false;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getUserFromStorage = () => {
  return window.localStorage.getItem('user') || 'Guest';
};

export const getUserRoutes = (routes) => {
  const userTypeId = parseInt(window.localStorage.getItem('userTypeID'), 10);
  return routes.filter((route) => route.enabledUserTypes.includes(userTypeId));
};

export const sha512 = async (str) => {
  return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
    return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
  });
};

export const valueToArray = (val) => {
  return Array.isArray(val) || val == null || val === '' ? val : [val];
};

export const getListInitValue = (list, valueKey = 'ID') => {
  return list.length === 1 ? list[0][valueKey] : null;
};

export const parseGateTokenPair = (gateCurrencyPairId, list) => {
  const currencyDetail = list.find((item) => item.gateCurrencyPairId === gateCurrencyPairId);

  return [currencyDetail['currencyId'], currencyDetail['gateTokenId']];
};

export const groupToMap = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const splitDateTimeToDate = (dateStr) => {
  return dateStr ? dateStr.split('T')[0] : dateStr;
};

export const generateKey = (length = 100) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const expiryDateFormat = (value) => {
  if (!value) return '';

  if (value.length === 2) {
    return value + '/';
  } else {
    return value;
  }
}

export const creditCardFormat = (cardNumber) => {
  if (!cardNumber) return '';
  const v = cardNumber.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = matches && matches[0] || '';
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  } else {
    return cardNumber;
  }
};

export const fromCreditCardFormat = (cardNumber) => {
  return cardNumber.replace(SPACES, '');
};

export const currencyFormat = (value) => {
  return value ? value.toFixed(2) : value;
};

function isNotUndefined(val) {
  return typeof val !== 'undefined';
}

export const valueWithPrecision = (value, precision) => {
  return isNotUndefined(value) && isNotUndefined(precision) ? value.toFixed(precision) : value;
};

export const cryptoDisplayExpression = (item, rateString) => {
  if (!item) return;
  let result = '';
  const {CurrencyName, CurrencyCode, BlockchainName, BlockchainCode, CurrencyRate} = item;
  const values = [CurrencyName, CurrencyCode, BlockchainName, BlockchainCode, CurrencyRate];
  const delimeters = ['', ' ', ' - ', ' ', ` - ${rateString} - `];

  for (let i = 0; i < values.length; i++) {
    if (values[i]) {
      if (i === 1 || i === 3) {
        values[i] = `(${values[i]})`;
      }
      result += delimeters[i] + values[i];
    }
  }
  //`${CurrencyName} (${CurrencyCode}) - ${BlockchainName} (${BlockchainCode}) - ${rate} - ${CurrencyRate}`
  return result.trim();
};

export const copyTextToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

export const parseGetParams = (str) => {
  const queryString = str.split('?')[1];
  if (!queryString) return null;

  const params = {};
  const keyValuePairs = queryString.split('&');

  for (let pair of keyValuePairs) {
    const [key, value] = pair.split('=');
    params[key] = decodeURIComponent(value || '');
  }

  return params;
}

export const prepareGridExport = (exportInstance, gridInstance, exportCaptions, t) => {
  exportInstance.beginUpdate();
  const exportColumns = exportInstance.getVisibleColumns();

  exportColumns.forEach((column) => {
    let caption;

    if (exportCaptions) {
      caption = exportCaptions[column.dataField];
    } else {
      const visibleColumns = gridInstance.getVisibleColumns();

      if (visibleColumns.length > 0) {
        const columnNames = visibleColumns.map((item) => item.dataField);
        caption = columnNames.includes(column.dataField) && gridInstance.columnOption(column.dataField, 'caption');
      }
    }

    if (caption) {
      exportInstance.columnOption(column.dataField, 'caption', t(caption));
    } else {
      exportInstance.columnOption(column.dataField, 'visible', false);
    }
  });

  exportInstance.endUpdate();
}

export const parseMultilineText = (text = '') => {
  return text.split('\n');
}

export const constructMinMaxString = (allowedPaymentParams, toCurrencyId) => {
  let res = '';

  if (allowedPaymentParams && toCurrencyId) {
    const match = allowedPaymentParams.find((item) => {
      return item.ForeignCurrencyID === toCurrencyId;
    });

    if (match) {
      const min = match.MinAmount != null ? `min: ${match.MinAmount}` : '';
      const max = match.MaxAmount != null ? `max: ${match.MaxAmount}` : '';

      res = `${min}${(min && max) ? ', ' : ''}${max}`;
    }
  }

  return res;
}

const icon = [
  '',
  'mdi-wallet-outline',
  'mdi-credit-card-outline',
  'mdi-bank',
  'mdi-shape-outline',
];

export const getGroupIcon = (id) => {
  return `mdi ${icon[id]}` || '';
}

export const camelToUpperSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter}`).substring(1).toUpperCase();

export const delayFunction = (ms) => {
  return function (x) {
    return new Promise(resolve => setTimeout(() => resolve(x), ms));
  };
}

export const delayDecorator = (f, ms = 500) => {
  return function () {
    setTimeout(() => f.apply(this, arguments), ms);
  };
}

export const randomIntFromInterval = (min, max) => { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const getExportFileName = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');

  return `${year}${month}${day}-${hours}${minutes}${seconds}`;
}

export const onCustomItemCreating = (args, {indexKey = 'ID', valueKey = 'Name'}) => {
  if (!args.text) {
    args.customItem = null;
    return;
  }

  const {component, text} = args;
  const currentItems = component.option('items');

  let newId;

  if (currentItems) {
    const lastItem = currentItems.at(-1);
    newId = lastItem[indexKey] + 1;
  } else {
    newId = 1;
  }

  const newItem = {
    ID: newId,
    [valueKey]: text.trim(),
  };

  args.customItem = newItem;
}

export function truncateString(str, length = 32) {
  if (!str || str.length <= length) return str;
  return str.slice(0, 10) + '...' + str.slice(-8);
}

export const getCardLogo = (cardNumber) => {
  const logoName = cardNumber.startsWith('4') ? 'visa' : cardNumber.startsWith('5') ? 'mc' : 'card';

  return `./images/accounts/small-logo/${logoName}.svg`;
}

export const checkIsCardKindTransaction = ({ForeignPaymentSystemTypeKindID, ForeignPaymentSystemTypeIsShowCardForm}) => {
  return ForeignPaymentSystemTypeKindID === 1 && ForeignPaymentSystemTypeIsShowCardForm === 1;
}

export const fetchLocalList = async (listName) => {
  const response = await fetch(`/api/${listName}.json`);
  if (!response.ok) {
    throw new Error('ERROR_MSG.COUNTRIES_LOAD_FAIL');
  }

  const contentType = response.headers.get('content-type');
  if (!contentType || !contentType.includes('application/json')) {
    throw new Error('ERROR_MSG.LIST_LOAD_FAIL');
  }

  return await response.json();
};

export const getPrecision = (value, currencyPrecision) => {
  return Number.isInteger(value) ? DEFAULT_PRECISION : currencyPrecision;
}
