import React from 'react';
import {camelToUpperSnakeCase, getPrecision, truncateString} from 'utils/functions';
import CopyButton from 'components/buttons/copy-button/copy-button';
import {vars} from 'utils/variables';
import {connect} from 'react-redux';

const {DEFAULT_PRECISION} = vars;

const WallettoFields = ({t, rowData, currencies}) => {
  rowData.InsDate = rowData.InsDate.replace('T', ' ');

  const {
    Amount, CurrencyCode, FeeAmount, ForeignAmount, ForeignAccountCode, ForeignClientName, ForeignCurrencyCode,
    CurrencyRate, CryptoParams, ForeignCurrencyID, CurrencyID,
  } = rowData;

  const simpleFields = [
    'InsDate',
    'PaymentTypeName',
    'PaymentStatusName',
  ];

  const extraSimpleFields = [
    'CryptoParams.BlockchainCode',
    'Mcc',
    'Rrn',
    'ApprovalCode',
    'Description',
  ];

  let complexFields = {};
  const commonFields = {};

  const fee = FeeAmount || 0;

  // https://wiki.macc.com.ua/maccsystems/pages/viewpage.action?pageId=60623336
  if ([2, 4, 6, 8, 10].includes(rowData.PaymentTypeID)) {

    const currency = currencies.find(item => item.ID === ForeignCurrencyID);
    const foreignCurrency = currencies.find(item => item.ID === CurrencyID);
    const currencyPrecision = currency && currency.ViewPrecision ? currency.ViewPrecision : DEFAULT_PRECISION;
    const foreignCurrencyPrecision = foreignCurrency && foreignCurrency.ViewPrecision ?
      foreignCurrency.ViewPrecision :
      DEFAULT_PRECISION;
    const amount = ForeignAmount || 0;
    const toReceive = Amount || 0;

    complexFields = {
      Amount: amount.toFixed(getPrecision(amount, currencyPrecision)) + " " + ForeignCurrencyCode,
      Fee: fee.toFixed(getPrecision(fee, foreignCurrencyPrecision)) + " " + ForeignCurrencyCode,
      ToReceive: toReceive.toFixed(getPrecision(toReceive, currencyPrecision)) + " " + CurrencyCode,
    };
  } else {

    const currency = currencies.find(item => item.ID === CurrencyID);
    const foreignCurrency = currencies.find(item => item.ID === ForeignCurrencyID);
    const currencyPrecision = currency && currency.ViewPrecision ? currency.ViewPrecision : DEFAULT_PRECISION;
    const foreignCurrencyPrecision = foreignCurrency && foreignCurrency.ViewPrecision ?
      foreignCurrency.ViewPrecision :
      DEFAULT_PRECISION;
    const amount = Amount || 0;
    const toTransfer = ForeignAmount || 0;

    complexFields = {
      Amount: amount.toFixed(getPrecision(amount, currencyPrecision)) + " " + CurrencyCode,
      Fee: fee.toFixed(getPrecision(fee, currencyPrecision)) + " " + CurrencyCode,
    };

    if (CryptoParams && CryptoParams.NetworkFee) {
      complexFields = {
        ...complexFields,
        NetworkFee: CryptoParams.NetworkFee.toFixed(getPrecision(CryptoParams.NetworkFee, foreignCurrencyPrecision)) + " " + CurrencyCode,
      };
    }

    complexFields = {
      ...complexFields,
      ToTransfer: toTransfer.toFixed(getPrecision(toTransfer, foreignCurrencyPrecision)) + " " + ForeignCurrencyCode,
    };
  }

  if (CurrencyRate !== 1) {
    commonFields.Rate = "1 " + CurrencyCode + " = " + CurrencyRate + " " + ForeignCurrencyCode;
  }

  commonFields.TransactionId = rowData.ID;

  const complexKeys = Object.keys(complexFields);
  const commonKeys = Object.keys(commonFields);

  return (
    <div className={'payment-detail-inner'}>
      {simpleFields.map((key) => {
        return rowData[key] ? (
          <div
            key={key}
            className={'payment-detail-item'}
          >
            <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(key)}`)}</span>
            <span className={'item-value'} title={rowData[key]}>{rowData[key]}</span>
          </div>
        ) : null;
      })}
      <div className={'payment-detail-item'}>
        <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.FOREIGN_CLIENT_NAME`)}</span>
        <div className={'item-value with-copy-button'}>
          <span className={'item-value'} title={ForeignClientName}>{ForeignClientName}</span>
          <CopyButton value={ForeignClientName}/>
        </div>
      </div>
      <div className={'payment-detail-item'}>
        <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.FOREIGN_ACCOUNT_CODE`)}</span>
        <div className={'item-value with-copy-button'}>
          <span className={'item-value'} title={ForeignAccountCode}>{truncateString(ForeignAccountCode)}</span>
          <CopyButton value={ForeignAccountCode}/>
        </div>
      </div>
      {extraSimpleFields.map((key) => {
        const [outerKey, innerKey] = key.split('.');

        if (innerKey && rowData[outerKey]) {
          return rowData[outerKey][innerKey] ? (
            <div
              key={key}
              className={'payment-detail-item'}
            >
              <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(innerKey)}`)}</span>
              <span className={'item-value'}>{rowData[outerKey][innerKey]}</span>
            </div>
          ) : null;
        } else {
          return rowData[key] ? (
            <div
              key={key}
              className={'payment-detail-item'}
            >
              <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(key)}`)}</span>
              <span className={'item-value'}>{rowData[key]}</span>
            </div>
          ) : null;
        }
      })}
      {complexKeys.map((key) => {
        return (
          <div
            key={key}
            className={'payment-detail-item'}
          >
            <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(key)}`)}</span>
            <span className={'item-value'} title={complexFields[key]}>{complexFields[key]}</span>
          </div>
        );
      })}
      {commonKeys.map((key) => {
        return commonFields[key] ? (
          <div
            key={key}
            className={'payment-detail-item'}
          >
            <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.${camelToUpperSnakeCase(key)}`)}</span>
            <span className={'item-value'} title={commonFields[key]}>{commonFields[key]}</span>
          </div>
        ) : null;
      })}
      {CryptoParams && CryptoParams.Txid ? (
        <div
          className={'payment-detail-item'}
        >
          <span className={'item-label'}>{t(`MAIN.PAYMENT_DETAIL.BLOCKCHAIN_ID`)}</span>
          <div className={'item-value with-copy-button'}>
            <span className={'item-value'} title={CryptoParams.Txid}>{truncateString(CryptoParams.Txid)}</span>
            <CopyButton value={CryptoParams.Txid}/>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currencies: state.currency?.currencies || [],
  }
}

export default connect(mapStateToProps, null)(WallettoFields);