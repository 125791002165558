import React from 'react';
import {getAccountsList2, getWallettoCards} from 'services/async';
import {getSharpayPayments, getWallettoPayments} from 'services/requestConsts';
import {groupToMap} from 'utils/functions';
import {accountIcon} from 'data/accounts';
import {Accounts} from './accounts';
import StartVerification from './start-verification/start-verification';
import {notifyApp} from 'utils/notifyWrapper';
import {vars} from 'utils/variables';
import WallettoTable from './account-detail-item/walletto-table';
import SharpayTable from './account-detail-item/sharpay-table';
import {getDataSource} from 'services/dataSource';
import AddAccountPopup from 'components/popup/add-account-popup';
import StartPanel from 'components/start-panel/start-panel';
import {LoadIndicator} from 'devextreme-react/load-indicator';
import {hideOtpPopup} from 'services/app/appActions';
import {connect} from 'react-redux';
import './main.scss';
import HorizontalBar from 'components/bars/horizontal-bar';
import AccountDetail from 'components/account/account-detail';

const {MAIN: {ACCOUNTS}} = vars;

const PaymentTable = (props) => {
  const {accountsMap, selectedDetailID, accountGroupID, accountPaymentsHistory} = props;
  const {SHARPAY_ID, WALLETTO_ID, SHARPAY_IBAN_ID, BANXE_ID} = ACCOUNTS;
  let viewPrecision = 2;
  let firstOfGroup;

  if (accountsMap && accountsMap.get(accountGroupID)[0]) {
    firstOfGroup = accountsMap.get(accountGroupID)[0];

    if (firstOfGroup) {
      if (accountGroupID === SHARPAY_ID) {
        viewPrecision = firstOfGroup['CurrencyViewPrecision']
      } else {
        viewPrecision = firstOfGroup['AccountCurrencyViewPrecision'];
      }
    }
  }

  switch (accountGroupID) {
    case SHARPAY_ID:
    case BANXE_ID:
      return <SharpayTable
        selectedDetailID={selectedDetailID}
        viewPrecision={viewPrecision}
        payments={accountPaymentsHistory}
      />;
    case WALLETTO_ID:
    case SHARPAY_IBAN_ID:
      return <WallettoTable
        selectedDetailID={selectedDetailID}
        viewPrecision={viewPrecision}
        payments={accountPaymentsHistory}
      />;
    default:
      return null;
  }
};

class NewMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountsMap: null,
      accountTabs: null,
      selectedTabIndex: 0,
      accountGroupID: -1,
      detailID: null,
      userNotVerified: false,
      isPopupVisible: false,
      contentReady: false,
      accountPaymentsHistory: [],
      isExternalRequested: false,
      wallettoCards: [],
    }

    this.delayedAccountUpdate = (paymentSystemTypeID, cb, ms = 6000) => {
      this.timerId = setTimeout(async () => {
        const request = await getAccountsList2(paymentSystemTypeID);

        if (request.isSuccess) {
          const accountsMap = groupToMap(
            [...request.list, ...this.state.wallettoCards],
            account => account.PaymentSystemTypeID
          );

          cb(Object.assign(accountsMap, this.state.accountsMap));
        }
      }, ms);
    }
  }

  componentDidMount() {
    this.loadAccounts().catch((e) => {
      console.log(e);
    });
  }

  componentWillUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  loadAccounts = async () => {
    const accounts = await getAccountsList2();
    const accountCards = await getWallettoCards();

    if (accounts.isSuccess && accountCards.isSuccess) {
      const wallettoCards = accountCards.list.filter(accountCard => accountCard.PaymentSystemTypeID === ACCOUNTS.WALLETTO_ID);
      const ibanCards = accountCards.list.filter(accountCard => accountCard.PaymentSystemTypeID === ACCOUNTS.SHARPAY_IBAN_ID);
      const accountsMap = groupToMap(
        [...accounts.list, ...wallettoCards],
        account => account.PaymentSystemTypeID
      );
      let tabs = [];
      let index = 0;

      const ACCOUNT_PAYMENT_SYSTEM_TYPE_ID = 'PaymentSystemTypeID';

      const ibanAccounts = accountsMap.get(ACCOUNTS.SHARPAY_IBAN_ID);

      if (ibanAccounts && ibanAccounts.length > 0) {
        ibanAccounts.forEach((account) => {
          account.NestedList = [];

          ibanCards.forEach((card) => {
            if (card.AccountID === account.ID) {
              account.NestedList.push(card);
            }
          })
        })
      }

      accountsMap.forEach((item) => {
        const accountIndex = item[0];
        const paymentType = accountIndex[ACCOUNT_PAYMENT_SYSTEM_TYPE_ID];
        const iconPath = accountIcon[paymentType] ? accountIcon[paymentType].icon : undefined;

        tabs.push({
          icon: iconPath,
          text: accountIndex['PaymentSystemTypeName'] || 'Unknown',
          id: accountIndex['PaymentSystemTypeID'],
          tabIndex: index,
        });
        index++;
      });

      let accountGroupID = -1;

      if (tabs[0]) {
        accountGroupID = tabs[0].id;
      }

      const newState = {
        accountsMap,
        accountGroupID,
        accountTabs: tabs,
        contentReady: true,
        accountList: accounts.list,
        wallettoCards: wallettoCards,
        detailID: accountsMap.size > 0 ? accountsMap.get(accountGroupID)[0]['ID'] : null,
      };

      this.setState(newState, this.getAccountPaymentsHistory);
    } else {
      if (
        accounts.response.ResponseCode === 'ACCOUNT_ERR__USER_NOT_VERIFIED' ||
        accountCards.response.ResponseCode === 'ACCOUNT_ERR__USER_NOT_VERIFIED'
      ) {
        this.setState({
          userNotVerified: true,
          contentReady: true,
        });
      } else {
        notifyApp(accounts.error);
      }
    }
  };

  onAccountTabChange = async (args) => {
    if (args.name === 'selectedItem' && args.value) {
      const {accountsMap, isExternalRequested} = this.state;

      if (!isExternalRequested && args.value.id !== ACCOUNTS.SHARPAY_ID) {
        this.delayedAccountUpdate(args.value.id, (newState) => {
          this.setState({...newState, isExternalRequested: true});
        }, 500);
      }

      this.setState({
        accountGroupID: args.value.id,
        selectedTabIndex: args.value.tabIndex,
        detailID: args.value.id === ACCOUNTS.WALLETTO_ID ?
          accountsMap.get(args.value.id)[0]['AccountID'] :
          accountsMap.get(args.value.id)[0]['ID'],
      }, this.getAccountPaymentsHistory);
    }
  };

  onAccountClick = async ({accountId}) => {
    this.setState({
      detailID: accountId,
    }, this.getAccountPaymentsHistory);
  };

  choosePaymentHistoryFetcher = (accountGroupID) => {
    const {SHARPAY_ID, BANXE_ID} = ACCOUNTS;
    const accountIsSharpayOrBanxe = accountGroupID === SHARPAY_ID || accountGroupID === BANXE_ID;

    return accountIsSharpayOrBanxe ? getSharpayPayments : getWallettoPayments;
  };

  getAccountPaymentsHistory = () => {
    const {detailID, accountGroupID} = this.state;
    const fetchPayments = this.choosePaymentHistoryFetcher(accountGroupID);

    const paymentHistoryObject = fetchPayments(detailID);
    const accountPaymentsHistory = getDataSource(paymentHistoryObject);

    this.setState({accountPaymentsHistory});
  };

  handlePopupOpen = () => {
    this.setState({
      isPopupVisible: true
    });
  };

  handlePopupClose = () => {
    this.setState({
      isPopupVisible: false
    });
  };

  successPopupHandler = () => {
    const {isOtpPopupVisible, hideOtpPopup} = this.props;
    if (isOtpPopupVisible) {
      hideOtpPopup();
    }
    this.handlePopupClose();
    this.loadAccounts().catch();
  };

  onDetailClick = (event, accountData) => {
    let newState;

    if (event) {
      const coords = event.target.getBoundingClientRect();
      accountData.coords = coords
      newState = {
        accountData: accountData,
        coords: coords,
      };
    } else {
      newState = {
        coords: null,
      };
    }

    console.group('newState')
    console.log(newState)
    console.groupEnd()
    this.setState(newState);
  }

  render() {
    const {
      accountsMap, accountTabs, detailID, selectedTabIndex, contentReady, accountList,
      accountGroupID, userNotVerified, accountPaymentsHistory, isPopupVisible
    } = this.state;

    const {currencies} = this.props;

    if (!contentReady) {
      return <div id={'load-wrapper'}>
        <LoadIndicator id='load-indicator'/>
      </div>;
    }

    return userNotVerified ? <StartVerification/> : (
      <div id={'flexi-main'} className={'animated-page'}>
        {this.state.coords ? (
          <AccountDetail
            accountData={this.state.accountData}
            coords={this.state.coords}
            onDetailClick={this.onDetailClick}/>
        ) : null}
        {accountGroupID === -1 ? (
          <StartPanel
            title={'COMMON.ACCOUNTS_CONNECTION'}
            description={['COMMON.MANAGE_YOUR_ACCOUNTS']}
            onClick={this.handlePopupOpen}
            linkText={'COMMON.ADD_ACCOUNT'}
          />
        ) : (
          <div className={'main-wrapper'}>
            <Accounts
              dataSource={accountTabs}
              selectedIndex={selectedTabIndex}
              onTabsSelectionChanged={this.onAccountTabChange}
              addButtonClickHandler={this.handlePopupOpen}
            />
            <HorizontalBar
              currencies={currencies}
              accountsMap={accountsMap}
              accountGroupID={accountGroupID}
              selectedAccountId={detailID}
              onAccountClick={this.onAccountClick}
              onDetailClick={this.onDetailClick}
              isDetailOpen={!!this.state.coords}
            />
            <div className={'grid-acc-wrapper'}>
              <PaymentTable
                accountsMap={accountsMap}
                selectedDetailID={detailID}
                accountGroupID={accountGroupID}
                accountPaymentsHistory={accountPaymentsHistory}
              />
            </div>
          </div>
        )}
        {isPopupVisible ? (
          <AddAccountPopup
            visible={isPopupVisible}
            handleClose={this.handlePopupClose}
            successHandler={this.successPopupHandler}
            accountList={accountList}
          />
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideOtpPopup: () => dispatch(hideOtpPopup()),
  }
}

const mapStateToProps = (state) => {
  return {
    isOtpPopupVisible: state.app.visible,
    currencies: state.currency.currencies,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewMain);